<template>
  <section>
    <!-- <el-row> -->
    <el-form :inline="true"
             size="mini"
             style="padding-left:10px">
      <el-form-item label="部门">
        <el-input v-model="dptnameinput"
                  placeholder="部门"
                  size="mini"
                  style="width: 70%;"
                  :clearable="true"></el-input>
      </el-form-item>
      <el-form-item label="账号"
                    style="margin-left: -50px;">
        <el-input v-model="usernameinput"
                  placeholder="账号"
                  size="mini"
                  style="width: 50%;"
                  :clearable="true"></el-input>
      </el-form-item>
      <el-form-item label="名称"
                    style="margin-left: -80px;">
        <el-input v-model="nameinput"
                  placeholder="人员名称"
                  size="mini"
                  style="width: 55%;"
                  :clearable="true"></el-input>
      </el-form-item>
      <el-form-item style="margin-left: -80px;">
        <el-radio-group v-model="poststatus"
                        size="mini"
                        v-show="alluserradioshow">
          <el-radio-button label="1"
                           :checked="true">有职务</el-radio-button>
          <el-radio-button label="2">无职务</el-radio-button>
          <el-radio-button label="0">全部</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item>
        <el-button type="primary"
                   size="mini"
                   v-if="searchShow"
                   plain
                   @click="postsearch">查询</el-button>
      </el-form-item>
    </el-form>
    <!-- </el-row> -->
    <!--组织维护树形工具条-->
    <div id="userdiv">
      <el-col :span="7"
              style="padding-left:10px;padding-right:10px;">
        <el-divider>组织结构</el-divider>
        <div id="dpttree">
          <!-- <el-tree :load="loadDptNode"
                   :props="RoleProps"
                   :data="treedata"
                   lazy
                   @node-click="PostClick"
                   v-if="datatree"
                   highlight-current
                   :render-content="renderContent"></el-tree>
                    -->

              <el-input
                size="mini"
                style="width:90%;margin-left:10px;margin-bottom:2px"
                :clearable="true"
                placeholder="输入部门名称关键字进行筛选"
                v-model="filterDptText">
              </el-input>
              <el-tree 
              :data="treedata"
              node-key="id"
              :default-expanded-keys="defaultExpandArr"
              @node-click="PostClick"
              v-if="datatree"
              highlight-current
              :filter-node-method="filterNode"
              ref="tree1"
              :render-content="renderContent"></el-tree>
        </div>
      </el-col>
      <!--待选人员列表-->
      <el-col :span="10"
              style="padding-left:10px;padding-right:10px;">
        <!-- <el-card> -->
        <el-divider>人员</el-divider>
        <div id="usertable">
          <el-table :data="userform"
                    highlight-current-row
                    @row-dblclick="addNewUser">
            <el-table-column type="index"
                             width="40px"></el-table-column>
            <el-table-column label="账号"
                             prop="username"
                             width
                             show-overflow-tooltip></el-table-column>
            <el-table-column label="姓名"
                             prop="name"
                             width
                             show-overflow-tooltip></el-table-column>
            <el-table-column label="部门"
                             prop="deptName"
                             width
                             show-overflow-tooltip></el-table-column>
            <el-table-column label="选择"
                             width="50px">
              <template scope="scope">
                <el-button type="success"
                           icon="el-icon-right"
                           size="mini"
                           circle
                           @click="addNewUser(scope.row)"></el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <!-- 分页器 -->
        <el-pagination :small="true"
                       align="center"
                       @current-change="handleCurrentChange"
                       :current-page="currentPage"
                       :page-size="pageSize"
                       layout="prev, pager, next"
                       :page-count="total"
                       :total="totaldata"
                       v-show="postshow"></el-pagination>
        <!--分页器2-->
        <el-pagination :small="true"
                       align="center"
                       @current-change="handleCurrentChange2"
                       :current-page="currentPage2"
                       :page-size="pageSize2"
                       layout="prev, pager, next"
                       :page-count="total2"
                       :total="totaldata2"
                       v-show="searchshow"></el-pagination>
        <!-- </el-card> -->
      </el-col>
      <!--已选人员列表-->
      <el-col :span="7"
              style="padding-left:10px;padding-right:10px;">
        <!-- <el-card> -->
        <el-divider>已选人员</el-divider>
        <div id="chooseusertable">
          <el-table :data="gotuserform"
                    highlight-current-row
                    @row-dblclick="deleteNewUser">
            <el-table-column type="index"
                             width="40px"></el-table-column>
            <el-table-column label="账号"
                             prop="UserName"
                             width
                             show-overflow-tooltip></el-table-column>
            <el-table-column label="姓名"
                             prop="UserNameClaim"
                             width
                             show-overflow-tooltip></el-table-column>
            <el-table-column label="移除"
                             width="50px">
              <template scope="scope">
                <el-button type="danger"
                           icon="el-icon-back"
                           size="mini"
                           circle
                           @click="deleteNewUser(scope.row)"></el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <!-- </el-card> -->
      </el-col>
    </div>
    <div slot="footer"
         style="text-align: center;">
      <el-button type="primary"
                 size="mini"
                 plain
                 @click="addNewSubmit">提交</el-button>
    </div>
    <!-- </el-dialog> -->
  </section>
</template>
<script>
import { GetChildrenList, GetDepartmentTypes, GetDepartmentPostUserList, GetUserChooseList,findUserDetailsByUserIds, GetPostUserPrincipalList } from '../api/hr';
export default {
  name: "Userchoose",
  data () {
    return {
      searchShow:true,
      //   dialogWidth: 0,
      currentPage: 1, // 当前页码
      total: null, // 总条数
      totaldata: null,
      pageSize: 5, // 每页的数据条数
      page: 1,
      currentPage2: 1, // 当前页码
      total2: null, // 总条数
      totaldata2: null,
      pageSize2: 5, // 每页的数据条数
      page2: 1,
      node2: null,    //暂存选中node信息
      alluserradioshow: false,
      deptname: null,   //双向绑定部门name
      dptnameinput: null,
      usernameinput: null,
      nameinput: null,
      poststatus: '1',
      filterDptText:'',
      postshow: true,
      searchshow: false,
      datatree: false,   //树重新加载
      userform: [],    //初始化待选名单列表
      gotuserform: [],  //初始化已选人员名单
      newcontent: null,  //暂存新增人员内容
      TypeIdModules: [],      //部门类型集合
      treedata:[],
      defaultExpandArr:[],
      RoleProps: {
        label: 'Name',
        isLeaf: 'Leaf'
      }
    }
  },
  props: ["limit", "data", "all", "single","deptId"], //接受父组件传值
  methods: {

    filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
    async loadTree()
    {
      let self=this;
      if(self.deptId==undefined){
        GetChildrenList({deptId:element}).then((res) => {
          // console.log(res);
          if(res.data.code==200)
          {
            self.treedata=res.data.data.dept;
            res.data.data.dept.forEach(element => {
              if(element.levelValue<=3)
              {
                self.defaultExpandArr.push(element.id);
              }
            });
            // self.treedata=res.data.data.dept;
            // console.log(self.treedata)
          }
        });
        return;
      }else if(this.deptId.length!=1){
        let mytree = new Array();
        var arr = self.deptId.split(',');
        var deptIdArr = [];
        arr.forEach(element => {
          if(element!=''){
            deptIdArr.push(element);
          }
        });

        // console.log(deptIdArr)
        for(var a = 0;a<deptIdArr.length;a++){
          var element = deptIdArr[a];
          // console.log(element)
          var orgList = await self.getOrglist(element);
          // console.log(orgList);
          mytree.push(orgList);
        }
        // console.log(mytree)
        var tree = [];
        mytree.forEach(element => {
          tree.push(element[0]);
        });
        self.treedata = tree;
      }
    },
    async getOrglist(element){
      var arr= [];
      await GetChildrenList({deptId:element}).then((res) => {
        // console.log(element)
        arr = res.data.data.dept;
        // console.log(arr);
      });
      return arr;
    },
    //组织维护节点加载初始化
    // loadDptNode (node, resolve) {
    //   if (node.level === 0) {
    //     this.loadfirstnode(resolve);
    //   }
    //   if (node.level >= 1 && node.isLeaf == false) {
    //     this.loadchildnode(node, resolve);
    //   }
    // },
    //组织维护加载第一级节点
    // loadfirstnode (resolve) {
    //   GetChildrenList().then((res) => {
    //     let topdpts = this.limit instanceof Array;
    //     if (topdpts == true && this.limit.length != 0) {
    //       resolve(this.limit);
    //     } else {
    //       const roledata = res.data.response;
    //       resolve(roledata);
    //     }
    //   });
    // },
    //组织维护加载节点的子节点集合
    // loadchildnode (node, resolve) {
    //   let para = { departmentId: node.data.Id };
    //   GetChildrenList(para).then((res) => {
    //     const roledata = res.data.response;
    //     resolve(roledata);
    //   });
    // },
    renderContent (h, { node, data, store }) {
      var icon = data.deptTypeIcon;
      // for (var i = 0; i < this.TypeIdModules.length; i++) {
      //   var tm = this.TypeIdModules[i];
      //   console.log(tm)
      //   if (tm.Id == data.TypeId) {
      //     icon = tm.Icon;
      //     break;
      //   }
      // }
      return (
        <span class="custom-tree-node">
          <i class={icon}>{node.label}</i>
        </span>
      )
    },
    getSearchData () {
      this.node2 = null;
      let limitIds = null;
      let topdpts = this.limit instanceof Array;
      if (topdpts == true && this.limit.length != 0) {
        limitIds = this.limit.map(function (item) {
          return item["Id"];
        });
      }

      let para = {
          pageNum: this.page2,
          pageSize: this.pageSize2,
          hasJob: this.poststatus,
          name: this.nameinput,
          isAllNextDept:false,
          isMainJob:true,
          username: this.usernameinput,
          deptName: this.dptnameinput,
        };
        
      GetDepartmentPostUserList(para).then((res) => {
        // console.log(res)
        if (res.data.data != null) {
          this.total2 = res.data.data.pages;
          this.totaldata2 = res.data.data.total;
          this.userform = res.data.data.list;
        }
      });

    },
    handleCurrentChange (val) {
      this.page = val;
      this.getPreUsers();
      this.page = 1;
    },
    handleCurrentChange2 (val) {
      this.page2 = val;
      this.getSearchData();
      this.page2 = 1;
    },
    //查询主职务人员
    postsearch () {
      this.postshow = false;
      this.searchshow = true;
      this.getSearchData();
    },
    //职务树节点点击事件
    PostClick (node) {
      this.postshow = true;
      this.searchshow = false;
      this.deptname = node.Name;
      this.node2 = node;
      this.getPreUsers();
    },
    getPreUsers () {
      let para;
      if (this.node2 != null) {
        let node3 = this.node2;
        // console.log(node3)
        para = {
          pageNum: this.page,
          pageSize: this.pageSize,
          deptId: node3.id,
          isAllNextDept:false,
          hasJob: 0
        };
      } else {
        para = {
          pageNum: this.page,
          pageSize: this.pageSize,
          deptId: '',
          isMainJob:true,
          isAllNextDept:false,
          hasJob: 0
        };
      }
      GetDepartmentPostUserList(para).then((res) => {
        // console.log(res)
        if (res.data.data != null) {
          this.total = res.data.data.pages;
          this.totaldata = res.data.data.total;
          this.userform = res.data.data.list;
        } else {
          this.userform = [];
        }
      });
    },
    //从待选人员中选择人员
    addNewUser (row) {
      // console.log(row)
      let para = Object.assign({}, row);
      let check = this.data instanceof Array;
      let adduser = {
        Id: row.id,
        UserName: row.username,
        UserNameClaim: row.name,
        DepartmentId: row.deptId,
        DepartmentName: row.deptName,
      };
      if (this.gotuserform == null) {
        this.gotuserform = new Array();
        this.gotuserform.push(adduser);
      } else if (this.single && this.gotuserform.length == 1) {
        this.$message({
          message: '当前人员只能选一人',
          type: "error"
        });
      } else {
        try {
          this.gotuserform.forEach(item => {
            if (item.Id == adduser.Id) {

              throw new Error();
            }
          })
          this.gotuserform.push(adduser);
        } catch (e) {
          this.$message({
            message: '人员已选择',
            type: "info"
          });
        }


      }
    },
    //删除以选中职务人员
    deleteNewUser (row) {
      this.gotuserform.splice(
        this.gotuserform.indexOf(row),
        1);
    },
    //提交选中新人员弹框
    addNewSubmit () {
      var newdata = new Array();
      var dname = this.deptname;
      newdata = this.gotuserform;
      // console.log(this.gotuserform)
      // for (var i = 0; i < this.gotuserform.length; i++) {
      //   newdata.push(this.gotuserform[i].Id);
      // }

      this.$emit("callback", newdata); //将值传给父组件
    },
    diaclose () {
      this.datatree = false;
      this.userform = [];
      this.usernameinput = null;
      this.nameinput = null;
      // this.poststatus = null;
      this.total = null;
      this.totaldata = null;
      this.pageSize = 5;
      this.currentPage = 1;
      this.deptname = null;
    }
  },
  mounted () {
    console.log(this.deptId);
    if(this.deptId==undefined){
      this.loadTree();
    }else if(this.deptId.length!=1){
      if(this.deptId.length!=0){
        this.searchShow = false;
      }
      this.loadTree();
    }
    // GetDepartmentTypes().then((res) => {
    //   // console.log(res);
    //   this.TypeIdModules = res.data.data;
    // });
    let check = this.data instanceof Array;
    let para;
    if (this.all) {
      this.alluserradioshow = true;
    } else {
      this.alluserradioshow = false;
    }
    this.userform = [];
    this.datatree = true;
    if (check == true && this.data.length != 0) {
      para = this.data;
    } else {
      para = [];
    }
  },
  watch: {
    deptId:{
      handler(val){
        console.log(val);
      }
    },
    //监听值变化
    // data: function () {
    data: {
        immediate: true,
        handler(val)
        {
          // console.log(val)
          let para = val;
          // console.log(para);
          var str = '';
          para.forEach(element => {
            str+=element.id;
            str+=',';
          });
          if(str.length>0){
            var mypara = {
              isMainJob:true,
              userIds:str
            }
            findUserDetailsByUserIds(mypara).then((res) => {
              // console.log(res);
              var arr = new Array();
              res.data.data.forEach(element => {
                var u ={
                  Id:element.id,
                  UserName:element.username,
                  UserNameClaim:element.name,
                  DepartmentId:element.deptId,
                  DepartmentName:element.deptName,
                };
                arr.push(u);
              });
              this.gotuserform = arr;
              // this.gotuserform = res.data.response;
            });
          }
        }
    },
    filterDptText(val) {
      this.$refs.tree1.filter(val);
    }
  }
}
</script>
<style scoped>
/deep/ .el-table__body tr.current-row > td {
  background: #fabe64 !important;
}

.el-col
  >>> .el-tree--highlight-current
  .el-tree-node.is-current
  > .el-tree-node__content {
  background-color: #fabe64;
}

.el-col >>> .el-tree-node__expand-icon.expanded {
  -webkit-transform: rotate(0deg) !important;
  transform: rotate(0deg) !important;
}
.el-col >>> .el-icon-caret-right:before {
  content: url('../assets/add.png') !important;
  font-size: 16px;
}
.el-col >>> .expanded:before {
  content: url('../assets/cancel.png') !important;
  font-size: 16px;
}
.el-col >>> .is-leaf.el-tree-node__expand-icon.el-icon-caret-right:before {
  content: '' !important;
  font-size: 16px;
}

.el-tree {
  min-width: 100%;
  font-size: 14px;
  display: inline-block;
}

#userdiv {
  height: calc(100vh - 350px);
}
.el-tree {
  min-width: 100%;
  font-size: 14px;
  display: inline-block;
}
#dpttree {
  overflow-y: auto;
  overflow-x: scroll;
  height: calc(100vh - 440px);
  /*width:200px;*/
  border: 0px solid blue;
}
#usertable {
  overflow-y: auto;
  overflow-x: scroll;
  height: calc(100vh - 440px);
  /*width:200px;*/
  border: 0px solid blue;
}
#chooseusertable {
  overflow-y: auto;
  overflow-x: scroll;
  height: calc(100vh - 440px);
  width: 100%;
  /*width:200px;*/
  border: 0px solid blue;
}

/* .el-scrollbar .el-scrollbar__wrap {overflow-x: hidden;}
.el-tree>.el-tree-node{

height: 350px; 
min-width: 100%;
display:inline-block;
} */
</style>